import footerlogo from "../../../assets/images/whitelogo.png";
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import secureimage from "../../../assets/images/secured-by-sectigo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const displaylist = ["/chatbot"];
  const footerdisplay = displaylist.includes(location.pathname);
  const navigate = useNavigate();

  return (
    <footer className={`bg-pc ${footerdisplay ? "hidden" : "block"} `}>
      <div className="container">
        <div className="flex flex-col gap-[48px] lg:flex-row items-start justify-between pt-[64px] lg:pt-[100px] text-white">
          <div>
            <img src={footerlogo} alt="cv-logo" className="pb-[25px]" />
            <img src={secureimage} alt="secureimage" />
            {/* <p className="pb-[10px] max-w-[82%]">
              52764637, Address Line 1 , Address Line 2
            </p>
            <p className="pb-[10px]">
              Number: <a href="tel:123 564 8524">123 564 8524</a>
            </p>
            <p className="pb-[10px]">
              Email:{" "}
              <a href="mailto:customersupport@curevalue.com">
                customersupport@curevalue.com
              </a>
            </p> */}
          </div>
          <div className="flex items-starts gap-[70px]">
            <div>
              <h5 className="font-bold leading-[24px] pb-[10px]">
                For partners
              </h5>
              <ul>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/hospitals-and-doctors.php"
                    // onClick={() => {
                    //   navigate("/hospitals-and-doctors");
                    // }}
                    target="_blank"
                  >
                    Hospitals and doctors
                  </Link>
                </li>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/airlines.php"
                    // onClick={() => {
                    //   navigate("/airlines");
                    // }}
                    target="_blank"
                  >
                    Airlines
                  </Link>
                </li>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/hotels.php"
                    // onClick={() => {
                    //   navigate("/hotels");
                    // }}
                    target="_blank"
                  >
                    Hotels
                  </Link>
                </li>
              </ul>
            </div>
            <div className="block lg:hidden">
              <h5 className="font-bold leading-[24px] pb-[10px]">
                About CureValue
              </h5>
              <ul>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/contact-us.php"
                    // onClick={() => {
                    //   navigate("/contact-us");
                    // }}
                    target="_blank"
                  >
                    Contact us
                  </Link>
                </li>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/about-us.php"
                    // onClick={() => {
                    //   navigate("/about-us");
                    // }}
                    target="_blank"
                  >
                    About us
                  </Link>
                </li>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/feature-list.php"
                    // onClick={() => {
                    //   navigate("/feature-list");
                    // }}
                    target="_blank"
                  >
                    Feature list
                  </Link>
                </li>
                <li className="pb-[10px]">
                  <Link to="https://curevalue.org/common/careers.php"
                    // onClick={() => {
                    //   navigate("/careers");
                    // }}
                    target="_blank"
                  >
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h5 className="font-bold leading-[24px] pb-[10px]">Resources</h5>
            <ul>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/passport-and-visa.php"
                  // onClick={() => {
                  //   navigate("/passport-and-visa");
                  // }}
                  target="_blank"
                >
                  Passport and visa
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/blogs-and-articles.php"
                  // onClick={() => {
                  //   navigate("/blogs-and-articles");
                  // }}
                  target="_blank"
                >
                  Blogs and articles
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/sitemap.php"
                  // onClick={() => {
                  //   navigate("/sitemap");
                  // }}
                  target="_blank"
                >
                  Sitemap
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/press.php"
                  // onClick={() => {
                  //   navigate("/press");
                  // }}
                  target="_blank"
                >
                  Press
                </Link>
              </li>
            </ul>
          </div>
          <div className="hidden lg:block">
            <h5 className="font-bold leading-[24px] pb-[10px]">
              About CureValue
            </h5>
            <ul>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/contact-us.php"
                  // onClick={() => {
                  //   navigate("/contact-us");
                  // }}
                  target="_blank"
                >
                  Contact us
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/about-us.php"
                  // onClick={() => {
                  //   navigate("/about-us");
                  // }}
                  target="_blank"
                >
                  About us
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/feature-list.php"
                  // onClick={() => {
                  //   navigate("/feature-list");
                  // }}
                  target="_blank"
                >
                  Feature list
                </Link>
              </li>
              <li className="pb-[10px]">
                <Link to="https://curevalue.org/common/careers.php"
                  // onClick={() => {
                  //   navigate("/careers");
                  // }}
                  target="_blank"
                >
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright pt-[50px] pb-[110px] lg:pb-[32px] flex flex-col-reverse items-start gap-[40px] lg:flex-row lg:items-center justify-between text-white">
          <div className="flex flex-col items-start lg:flex-row lg:items-center gap-[16px] lg:gap-[40px]">
            <p className="text-anchortext">
              © 2024 CureValue,Inc. All rights reserved.
            </p>
            <div className="flex items-center gap-[24px]">
              <Link to="https://curevalue.org/common/privacy.php"
                target="_blank"
              >
                <p className="text-anchortext">Privacy policy</p>
              </Link>
              <Link to="https://curevalue.org/common/terms-of-service.php"
                target="_blank"
              >
                <p className="text-anchortext">Terms of service</p>
              </Link>
            </div>
          </div>
          {/* <div className="socialmedia flex items-center gap-[4px] lg:gap-[15px]">
            <a href="#">
              <FaFacebook size={"21px"} />
            </a>
            <a href="#">
              <FaInstagram size={"21px"} />
            </a>
            <a href="#">
              <FaTiktok size={"21px"} />
            </a>
            <a href="#">
              <FaLinkedin size={"21px"} />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
